* {
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Montserrat', sans-serif;
}

.font-raleway {
  font-family: 'Raleway', sans-serif;
}
.font-montaga {
  font-family: 'Montaga', serif;
}

.hero h1 {
  line-height: 108.5%;
  letter-spacing: -2px;
}

.hero h2 {
  opacity: 0.699999988079071;
  line-height: 140%;
  align-self: center;
}

.hero h1::before {
  content: url(../src/assets/svg/star.svg);
  margin-right: 10px;
}

.hero-card--text {
  text-shadow: 0px 3px 3px rgba(0, 0, 0, 0.25);
}

.success h3 {
  color: #ECE2DC;
  opacity: 0.5;
  line-height: 120%;
}

.success-box {
  border-radius: 8.879px;
  border: 1px solid rgba(251, 165, 153, 0.30);
  background: var(--darkmode-pallet-shades-of-green-shade-3, #00141B);
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.10), 6px 7px 21px 0px rgba(0, 0, 0, 0.10), 26px 29px 38px 0px rgba(0, 0, 0, 0.09), 58px 64px 52px 0px rgba(0, 0, 0, 0.05), 103px 114px 62px 0px rgba(0, 0, 0, 0.01), 162px 178px 67px 0px rgba(0, 0, 0, 0.00);
}

.border-right {
  border-right: 1px solid #bababa1f;
}

.border-right p {
  opacity: 0.8999999761581421;
}

.hire {
  background-image: url(../src/assets/img/bg.png);
  background-size: cover;
}

.text-gradient {
  color: #9BBFB5;
}

.platform  h2{
  color: rgba(239, 244, 245, 0.50);
  text-align: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  line-height: 140%;
  letter-spacing: -2px;
}

.platform-box {
  display: flex;
  padding: 8px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
}

.platform-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 10px 10px 0px 0px;
  border: 1px solid#B8D9D0;
}

.download {
  border-radius: 40px;
  background: #B8D9D0;
}

.foot {
  border-top: 1px solid #667A81;
  text-align: center;
  color: #667A81
}

.swiper-pagination {
  position: relative !important;
  display: flex !important;
  justify-content: center !important;
}

.swiper-wrapper {
  box-sizing: border-box !important;
}

.swiper-button-next::after, 
.swiper-button-prev::after {
  display: none;
}

.swiper-button-prev::before {
  content: url(../src/assets/svg/right.svg);
}

.swiper-button-next::before {
  content: url(../src/assets/svg/left.svg);
  margin-left: 20px;
}

.swiper-pagination-bullet {
  background-color: #ECE2DC !important;
}

.synergy-swiper .swiper-pagination-bullet {
  background-color: transparent !important;
  border: 2px solid #FFFFFF !important;
}

.synergy-swiper .swiper-pagination-bullet-active {
  background-color: #FFFFFF !important;
}

.foots {
  background: #519E8A;
  width: 100%;
  font-weight: 400;
}

.join-synergy1{
  line-height: 110%;
letter-spacing: -2.4px;
}

.about-about{
  background: linear-gradient(116deg, #F97D01 45.29%, #FB5046 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}


.otp-input::placeholder {
  text-align: center;
  color: #ECE2DC;
}

.synergy-contact .navlight {
  padding-top: 24px;
  padding-bottom: 24px;
}

.auth .synergy-nav {
  padding: 16px 24px !important;
  display: flex !important;
}

.about-join:hover {
transform: scale(1.2, 1.1);
transition: all 0.5s ease-in ;
}

.custom-dropdown {
  -webkit-appearance: none; /* Safari and Chrome */
  -moz-appearance: none; /* Firefox */
  appearance: none; /* For other modern browsers */

  background-image: url("/public/images/arrow-down-ash.svg");
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 16px; 
padding-inline: 16px;
}



@media (min-width: 1200px) {
  .auth .synergy-nav {
    display: none !important;
  }
}

.box-form .synergy-nav {
  display: flex !important;
  padding: 16px 24px !important;
}

@media (min-width: 1200px) {
  .box-form .synergy-nav {
    display: none !important;
  }
}
